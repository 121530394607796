import React, { Component } from 'react';
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import logo from "./DubberMSTeamsSmall.png";
import {NavLink} from "reactstrap";
import {Link} from "react-router-dom";
import Switch from "react-switch";
import {ChoiceModal} from "./ChoiceModal";
import {ConfirmModal} from "./ConfirmModal";
import {AlertModal} from "./AlertModal";

class AdSyncSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adGroups: [],
            choiceModalVisible: false,
            confirmModalVisible: false,
            productCode: null,
            allowPortalAccess: false,
            deleteAction: 'suspend',
            synchronizationPlans: [],
            focusedGroupId: null,
            syncNowPending: false,
            syncNowApiError: null,
            isRunNowDisabled: false,
            alertModalTitle: "",
            alertModalText: "",
            alertModalVisible: false,
            availableProductCodes: [],
            isSaveChangesDisabled: false
        }

        this.getSyncPlans = this.getSyncPlans.bind(this);
        this.createSyncPlan = this.createSyncPlan.bind(this);
        this.deleteSyncPlan = this.deleteSyncPlan.bind(this);
        this.createSyncPlan = this.createSyncPlan.bind(this);
        this.syncNow = this.syncNow.bind(this);
        this.OnNoItems = this.OnNoItems.bind(this);
        this.updateAllSyncPlans = this.updateAllSyncPlans.bind(this);
        this.loadAvailableProductCodesToState = this.loadAvailableProductCodesToState.bind(this);
        this.openChoiceModal = this.openChoiceModal.bind(this);
        this.onSaveChanges = this.onSaveChanges.bind(this);
        this.doSave = this.doSave.bind(this);
        this.showInvalidProductCodeAlert = this.showInvalidProductCodeAlert.bind(this);
    }
    
    loadAzureAzureAdGroupsToState(nextLink) {
        this.setState({
            loading: true
        });
        let config = {
            headers: {
                'Authorization': 'Jwt ' + this.props.accessToken
            }
        };
        let body = {
            portal: this.props.portalCode,
            tenantId: this.props.tenantId,
            nextLink: nextLink,
            type: 'adsync'
        };
        axios.post(this.props.aadGroupsUrl, body, config)
            .then(taskResponse => {
                
                let adGroups = this.state.adGroups;
                
                if (taskResponse.data.groups !== undefined)
                    adGroups = adGroups.concat(taskResponse.data.groups);
                
                this.setState({
                    adGroups: adGroups !== null ? adGroups : []
                })
                
                if (taskResponse.data !== undefined && taskResponse.data !== '' && taskResponse.data.nextLink !== null){
                    this.loadAzureAzureAdGroupsToState(taskResponse.data.nextLink);
                }
                else {
                    this.setState({loading: false});
                }
            });
    }

    componentDidMount() {
        this.loadAvailableProductCodesToState().then(() =>
        {
            this.loadAzureAzureAdGroupsToState();
            this.getSyncPlans();
        });
    }
    
    loadAvailableProductCodesToState() {
        return new Promise((resolve, reject) => {
            if (this.props.accountType === "group") {
                this.setState({
                    availableProductCodes: [
                        "UCRY-I-01",
                        "UCRT-T-01",
                        "UCRP-T-01"
                    ]
                });
                resolve();
                return;
            }

            let config = {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            };
            let body = {
                portal: this.props.portalCode,
                authId: this.props.authId,
                authToken: this.props.authToken,
                slug: this.props.slug,
            };
            axios.post(this.props.availableProductCodesUrl, body, config)
                .then(taskResponse => {
                    if (taskResponse.data === undefined) {
                        return;
                    }

                    this.setState({
                        availableProductCodes: taskResponse.data
                    });
                    
                    resolve();
                })
                .catch(error => {
                    reject(error);
                })
        });
    }
    
    getAdGroupNameByAdGroupIdentifier(groupId) {
        if (this.state.adGroups === null || this.state.adGroups.length === 0){
            return groupId + ' (Unknown AD Group)';
        }
        
        let group = this.state.adGroups.find(obj => {
           return obj.id === groupId;
        });
        
        if (group === undefined || group === null) return groupId + ' (Unknown AD Group)';
        
        return group.displayName;
    }
    
    loadSettingsFromSyncPlans() {
        if (this.state.synchronizationPlans == null ||
        this.state.synchronizationPlans.length === 0) {
            if (this.props.accountType !== "group") {
                this.setState({productCode: this.state.availableProductCodes[0]});
            }
            
            return;
        }
        
        let firstSyncPlan = this.state.synchronizationPlans[0];
        
        this.setState({
            deleteAction: firstSyncPlan.deleteAction,
            allowPortalAccess: firstSyncPlan.allowPortalAccess,
            productCode: firstSyncPlan.productCode
        }, this.forceUpdate);
    }

    getSyncPlans() {
        let config = {
            headers: {
                'Authorization': 'Jwt ' + this.props.accessToken
            }
        };
        let url = this.props.adSyncUrl + '/' + this.props.slug + '/' + this.props.tenantId + '?portal=' + this.props.portalCode;
        axios.get(url, config)
            .then(taskResponse => {
                let synchronizationPlans = taskResponse.data !== null ? taskResponse.data : [];
                this.setState({synchronizationPlans: synchronizationPlans}, this.loadSettingsFromSyncPlans);
            })
    }   
    
    isValidProductCode(){
        let config = {
            headers: {
                'Authorization': 'Jwt ' + this.props.accessToken
            }
        };

        let validateProductCodeBody = {
            portal: this.props.portalCode,
            authId: this.props.authId,
            authToken: this.props.authToken,
            slug: this.props.slug,
            productCode: this.state.productCode
        }
        
        return new Promise((resolve, reject) => {
            axios.post(this.props.productCodeValidationUrl, validateProductCodeBody, config)
                .then(taskResponse => {
                    if (taskResponse.data === false) {
                        reject();
                    }
                    
                    resolve();
                })
                .catch(error => {
                    reject();
                });
        });
    }

    createSyncPlan(groupId) {
        this.setState({choiceModalVisible: false});
        
        let config = {
            headers: {
                'Authorization': 'Jwt ' + this.props.accessToken
            }
        };
        
        let validateProductCodeBody = {
            portal: this.props.portalCode,
            authId: this.props.authId,
            authToken: this.props.authToken,
            slug: this.props.slug,
            productCode: this.state.productCode
        }
        
        let createBody = {
            tenantId: this.props.tenantId,
            adSourceGroupId: groupId,
            dubberAccountSlug: this.props.slug,
            portalCode: this.props.portalCode,
            productCode: this.state.productCode,
            allowPortalAccess: this.state.allowPortalAccess,
            deleteAction: this.state.deleteAction
        };

        if (this.props.accountType === "group") {
            return new Promise((resolve, reject) => {
                axios.post(this.props.productCodeValidationUrl, validateProductCodeBody, config)
                    .then(taskResponse => {
                        if (taskResponse.data === false) {
                            this.setState({
                                alertModalTitle: "Invalid product code",
                                alertModalText: "The selected product code is invalid. Please check the product code and try again.",
                                alertModalVisible: true,
                                choiceModalVisible: false
                            })

                            reject();
                            return;
                        }

                        axios.post(this.props.adSyncUrl, createBody, config)
                            .then(taskResponse => {
                                this.getSyncPlans();
                                resolve();
                            })
                            .catch(error => {
                                reject();
                            });
                    })
                    .catch(error => {
                        reject();
                    });
            });
        }
        else {
            return new Promise((resolve, reject) => {
                axios.post(this.props.adSyncUrl, createBody, config)
                    .then(taskResponse => {
                        this.getSyncPlans();
                        resolve();
                    })
                    .catch(error => {
                        reject();
                    });
            });
        }
    }
    
    deleteSyncPlan(groupId, updateState = true) {
        this.setState({confirmModalVisible: false});
        
        if (groupId === undefined) groupId = this.state.focusedGroupId;

        let config = {
            headers: {
                'Authorization': 'Jwt ' + this.props.accessToken
            }
        };
        let url = this.props.adSyncUrl + '/' + this.props.slug + '/' + this.props.tenantId + '/' + groupId + '?portal=' + this.props.portalCode;
        return new Promise((resolve, reject) => {
            axios.delete(url, config)
                .then(taskResponse => {
                    if (updateState) this.getSyncPlans();
                    resolve();
                })
                .catch(error => { reject() });
        });
    }
    
    syncNow() {
        if (this.state.synchronizationPlans.length === 0){
            this.setState({
                alertModalTitle: "Unable To Sync Now",
                alertModalText: "Please add a sync plan first, then click run now again.",
                alertModalVisible: true,
                choiceModalVisible: false
            }, this.forceUpdate);
        }
        else {
            this.setState({
                alertModalVisible: false,
                confirmModalVisible: false,
                choiceModalVisible: false,
                syncNowPending: true,
                isRunNowDisabled: true,
            }, this.forceUpdate);
            let config = {
                headers: {
                    'Authorization': 'Jwt ' + this.props.accessToken
                }
            };
            this.state.synchronizationPlans.forEach(d => {
                let url = this.props.adSyncUrl + '/syncnow/' + this.props.slug + '/' + this.props.tenantId + '/' + d.adSourceGroupId + '?portal=' + this.props.portalCode;
                axios.post(url, {}, config)
                    .then(() => {
                        this.setState({
                            syncNowApiError: null,
                            alertModalVisible: false,
                            confirmModalVisible: false,
                            choiceModalVisible: false,
                            isRunNowDisabled: false
                        }, this.forceUpdate);
                    })
                    .catch((error) => {
                        if (error.response.status === 429){
                            this.setState({
                                syncNowApiError: error.response.data.Message,
                                isRunNowDisabled: true,
                                alertModalVisible: false,
                                confirmModalVisible: false,
                                choiceModalVisible: false
                            }, this.forceUpdate);

                            setTimeout(() => this.setState({ isRunNowDisabled: false }), (error.response.data.RetryAfter * 1000));
                        }
                        else {
                            this.setState({
                                syncNowApiError: "Unknown error. Please try again later.",
                                alertModalVisible: false,
                                confirmModalVisible: false,
                                choiceModalVisible: false,
                                isRunNowDisabled: false
                            }, this.forceUpdate);
                        }
                    });
            })
        }
    }

    updateAllSyncPlans() {
        let promises = [];
        
        this.setState({isSaveChangesDisabled: true}, this.forceUpdate);
        
        this.state.synchronizationPlans.forEach(d => {
            promises.push(
                new Promise((resolve, reject) => {
                    this.deleteSyncPlan(d.adSourceGroupId, false)
                        .then(() => {
                            promises.push(this.createSyncPlan(d.adSourceGroupId, false)
                                .then(() => { resolve(); })
                                .catch(() => { reject(); }))
                        })
                        .catch(() => { reject();})
                }));
        })
        
        Promise.all(promises).then(p => {
            this.getSyncPlans();
            this.setState({isSaveChangesDisabled: false}, this.forceUpdate);
        }).catch(() => {
            this.setState({isSaveChangesDisabled: false}, this.forceUpdate);
        })
    }

    productCode(name) {
        if (name === undefined || name === null || name === '')
            return null;
        
        let result = name;
      if (name.toLower().startsWith("call")) {
        result = "CDU-T-01";
      } else if (name.toLower().startsWith("dub ai")) {
        result = "DAI-T-01";
      } else if (name.toLower().startsWith("dubbergo sp edition")) {
        result = "DGO-I-01";
      } else if (name.toLower().startsWith("dubbergo uc edition")) {
        result = "DGOUC-I-01";
      } else if (name.toLower().startsWith("dubber cr sp edition")) {
        result = "DGOSP-T-01";
      } else if (name.toLower().startsWith("dubber recording")) {
        result = "DR_T_01";
      } else if (name.toLower().startsWith("dubber recording + trends")) {
        result = "DR_T_02";
      } else if (name.toLower().startsWith("dubber unified capture")) {
        result = "DUR_T_01";
      } else if (name.toLower().startsWith("unified capture + 1")) {
        result = "DUR_T_02";
      } else if (name.toLower().startsWith("dubber insights")) {
        result = "DURI_T_01";
      } else if (name.toLower().startsWith("dubber you")) {
        result = "UCRY-I-01";
      } else if (name.toLower().startsWith("dubber teams")) {
        result = "UCRT-T-01";
      } else if (name.toLower().startsWith("dubber premier")) {
        result = "UCRP-T-01";
      } else if (name.toLower().startsWith("dubber compliance edition")) {
        result = "UCRC-C-01";
      } else if (name.toLower().startsWith("dubber premier compliance edition")) {
        result = "UCRP-C-01";
      } else if (name.toLower().startsWith("notes by dubber - quick notes")) {
        result = "NBDQN-T-01";
      } else if (name.toLower().startsWith("notes by dubber - moments")) {
        result = "NBDMOM-T-01";
      } else if (name.toLower().startsWith("notes by dubber - pro")) {
        result = "NBDPRO-T-01";
      }

        return result;
    }

    productName(product) {
        if (!product)
            return "";
        
        let result = product;
      if (product.toUpperCase().startsWith("DAI")) {
        result = "Dub AI";
      } else if (product.toUpperCase().startsWith("DGO-I")) {
        result = "DubberGo SP Edition";
      } else if (product.toUpperCase().startsWith("DGOUC-I")) {
        result = "DubberGo UC Edition";
      } else if (product.toUpperCase().startsWith("DGOSP-T")) {
        result = "Dubber CR SP Edition";
      } else if (product.toUpperCase().startsWith("DR_T_02")) {
        result = "Dubber RECORDING + TRENDS";
      } else if (product.toUpperCase().startsWith("DR_T")) {
        result = "Dubber RECORDING";
      } else if (product.toUpperCase().startsWith("DUR_T_02")) {
        result = "Unified Capture + 1";
      } else if (product.toUpperCase().startsWith("DUR_T")) {
        result = "Dubber UNIFIED CAPTURE";
      } else if (product.toUpperCase().startsWith("DURI_T")) {
        result = "Dubber INSIGHTS";
      } else if (product.toUpperCase().startsWith("UCRY-I")) {
        result = "Dubber You";
      } else if (product.toUpperCase().startsWith("UCRT-T")) {
        result = "Dubber Teams";
      } else if (product.toUpperCase().startsWith("UCRP-T")) {
        result = "Dubber Premier";
      } else if (product.toUpperCase().startsWith("UCRC-C")) {
        result = "Dubber Compliance Edition";
      } else if (product.toUpperCase().startsWith("UCRP-C")) {
        result = "Dubber Premier Compliance Edition";
      } else if (product.toUpperCase().startsWith("NBDMOM-T")) {
        result = "Notes by Dubber - Moments";
      } else if (product.toUpperCase().startsWith("NBDQN-T")) {
        result = "Notes by Dubber - Quick Notes";
      } else if (product.toUpperCase().startsWith("NBDPRO-T")) {
        result = "Notes by Dubber - Pro";
      }

        return result;
    }
    
    OnNoItems() {
        this.setState({
            alertModalTitle: "No options available",
            alertModalText: "All available active directory groups have been added to synchronization plans already.",
            alertModalVisible: true,
            choiceModalVisible: false
        })
    }
    
    openChoiceModal() {
        if (this.state.productCode === null){
            this.setState({
                choiceModalVisible: false,
                confirmModalVisible: false,
                alertModalVisible: true,
                alertModalTitle: "Select A Product",
                alertModalText: "Please select a dubber product before adding a sync plan.",
            }, this.forceUpdate);
        }
        else {
            this.setState({
                choiceModalVisible: true,
                confirmModalVisible: false
            }, this.forceUpdate);
        }
    }
    
    onSaveChanges() {
        this.setState({
            alertModalVisible: false,
            choiceModalVisible: false,
            confirmModalVisible: false,
            isSaveChangesDisabled: true
        }, this.forceUpdate);
        
        if (this.props.accountType === "group") {
            this.isValidProductCode().then(() => {
                this.doSave();
            }).catch(() => {
                this.showInvalidProductCodeAlert();
                this.setState({isSaveChangesDisabled: false});
            });
        }
        else {
            this.doSave();
        }
    }
    
    showInvalidProductCodeAlert() {
        this.setState({
            alertModalTitle: "Invalid product code",
            alertModalText: "The selected product code is invalid. Please check the product code and try again.",
            alertModalVisible: true,
            choiceModalVisible: false
        });
    }
    
    doSave() {
        if (this.state.synchronizationPlans === null ||
            this.state.synchronizationPlans.length === 0){
            this.setState({
                isSaveChangesDisabled: false,
                choiceModalVisible: false,
                confirmModalVisible: false,
                alertModalVisible: true,
                alertModalTitle: "Add A Sync Plan",
                alertModalText: "Please create a sync plan before saving changes.",
                isSaveChangesDisabled: false
            }, this.forceUpdate);
        }
        else if (this.state.productCode === null){
            this.setState({
                isSaveChangesDisabled: false,
                choiceModalVisible: false,
                confirmModalVisible: false,
                alertModalVisible: true,
                alertModalTitle: "Select A Product",
                alertModalText: "Please select a dubber product before saving changes.",
                isSaveChangesDisabled: false
            }, this.forceUpdate);
        }
        else {
            this.updateAllSyncPlans();

            this.setState({
                choiceModalVisible: false,
                confirmModalVisible: false,
                alertModalVisible: true,
                alertModalTitle: "Changes Saved",
                alertModalText: "Your changes have been save & applied to your sync plans."
            }, this.forceUpdate);
        }
    }

    render() {

        const noSyncPlans = <div className="divTableRow">
            <div className="divTableCell column2">No Active Syncronization Plans added.<br/>Click <b>Add Sync Plan</b> to get started.</div>
            <div className="divTableCell column1"></div>
        </div>
        
        const syncPlans =  this.state.synchronizationPlans !== null && this.state.synchronizationPlans.length > 0 ? this.state.synchronizationPlans.map((plan) => {
            return <div className="divTableRow" key={plan.adSourceGroupId}>
                <div className="divTableCell column2">{this.getAdGroupNameByAdGroupIdentifier(plan.adSourceGroupId)}</div>
                <div className="divTableCell column1">
                    <button onClick={() => {
                        this.setState({
                            choiceModalVisible: false,
                            confirmModalVisible: true,
                            focusedGroupId: plan.adSourceGroupId
                        })
                    }} className="next-link" >Delete</button>
                </div>
            </div>
        }) : noSyncPlans;
        
        const mostRecentSync = this.state.synchronizationPlans !== null && this.state.synchronizationPlans.length > 0 ?
            this.state.synchronizationPlans.sort((a,b) => Date.parse(a.lastRunTimeUTC) - Date.parse(b.lastRunTimeUTC))[0]
            : null;
        
        const dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true }
        
        let lastRunMessage = ""

        if (this.state.syncNowApiError !== null) {
            lastRunMessage = <div className="alert alert-danger mt-4 mr-4">
                {this.state.syncNowApiError}
            </div>
        }
        else if (this.state.syncNowPending === true) {
            lastRunMessage = <div className="alert alert-warning mt-4 mr-4">
                A run now request has been made. Please check back in an hour.
            </div>
        }
        else if (mostRecentSync == null) {
            lastRunMessage = <div className="alert alert-warning mt-4 mr-4">
                No sync plans configured
            </div>
        }
        else if (mostRecentSync.lastRunResult === false 
            && (new Date(mostRecentSync.lastRunTimeUTC).getFullYear()) !== 1){
            lastRunMessage = <div className="alert alert-danger mt-4 mr-4">
                Last run at {new Date(mostRecentSync.lastRunTimeUTC.toString() + 'Z').toLocaleString('en-US', dateFormat)} (Local Time) failed.
            </div>
        }
        else if (new Date(mostRecentSync.lastRunTimeUTC).getFullYear() === 1) {
            lastRunMessage = <div className="alert alert-warning mt-4 mr-4">
                Sync pending. Please check back in an hour.
            </div>
        }
        else {
            lastRunMessage = <div className="alert alert-success mt-4 mr-4">
                Last run at {new Date(mostRecentSync.lastRunTimeUTC.toString() + 'Z').toLocaleString('en-US', dateFormat)} (Local Time) successful.
            </div>
        }
        
        let choiceOptions = this.state.adGroups;
        
        if (this.state.synchronizationPlans !== null && this.state.synchronizationPlans.length > 0) {
            choiceOptions = choiceOptions.filter(f => {
                return this.state.synchronizationPlans.filter(e => e.adSourceGroupId === f.id).length === 0;
            });
        }

        choiceOptions = choiceOptions === null || choiceOptions.length === 0 
            ? [] 
            : choiceOptions.map(result =>
                ({
                    value: result.id,
                    text: result.displayName
                }))
        
        const productOptions = this.state.availableProductCodes.map((plan) => {
            return <option value={this.productName(plan)} key={plan} selected={plan === this.state.productCode}>{this.productName(plan)}</option>
        });
        
        return (
            <div id="main" className="ui text container transition">
                <div className="ui center aligned container head">
                    <div className={this.state.loading ? "spinner-div" : "spinner-hidden"}>
                        <div className="middle-of-page">
                            <Ring />
                        </div>
                    </div>
                </div>

                <div id="adsyncsettings" className="wizard-step">
                    <div className="no-top-margin">
                        <div className="ui center aligned container head">
                            <div className="stepheader">
                                <div className="step">AD Sync Settings</div>
                                <img src={logo} alt="Dubber MS Teams" className="small-logo" />
                                <div className="step-back"><NavLink tag={Link} to="/">&lt; Back</NavLink></div>
                                <div className="ui aligned container start">
                                    <div className="step-header">
                                        <h3 className="mb-4">Configure AD Sync</h3>
                                        
                                        <span>Tennant {this.props.domainName}    Id: {this.props.tenantId}</span>

                                        {lastRunMessage}

                                        <button onClick={this.openChoiceModal} className="next-link" >Add Sync Plan</button>
                                        <div className="mt-4 mr-4">
                                            <div className="ui aligned container start">
                                                <div className="divTable">
                                                    <div className="divTableRow">
                                                        <div className="divTableHead column2">Group</div>
                                                        <div className="divTableHead column1"></div>
                                                    </div>
                                                    <div className="divTableRow">
                                                        <div className="divTableCell column2"></div>
                                                        <div className="divTableCell column1"></div>
                                                    </div>
                                                    {syncPlans}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 width-half">
                                            <span>When a user is added to the AD Group:</span><br/>
                                            <div className="ml-4 mt-1">
                                                <Switch id="removeUserSwitch"
                                                        borderRadius={9}
                                                        height={18}
                                                        width={36}
                                                        onChange={(value) => {
                                                            this.setState({
                                                                allowPortalAccess: value,
                                                                choiceModalVisible: false,
                                                                confirmModalVisible: false,
                                                                alertModalVisible: false})
                                                        }}
                                                        checked={this.state.allowPortalAccess} />
                                                <span className="ml-1">Allow the user to log in to the dubber portal</span>
                                            </div>
                                        </div>

                                        <div className="mt-4 width-half">
                                            <span>Select A Dubber Product:</span><br/>
                                            {this.props.accountType === "group" ?
                                                <>
                                                    <input id="groupProductInput" list="groupProducts"
                                                         onChange={(event) => {
                                                             this.setState({
                                                                 choiceModalVisible: false,
                                                                 confirmModalVisible: false,
                                                                 alertModalVisible: false,
                                                                 productCode: this.productCode(event.target.value)
                                                             })
                                                         }
                                                         } placeholder="Select Product"
                                                         defaultValue={this.productName(this.state.productCode)}/>
                                                    <datalist id="groupProducts">
                                                        {productOptions}
                                                    </datalist>
                                                </>
                                                :
                                                <select id="groupProductInput" 
                                                       onChange={(event) => {
                                                           this.setState({
                                                               choiceModalVisible: false,
                                                               confirmModalVisible: false,
                                                               alertModalVisible: false,
                                                               productCode: this.productCode(event.target.value)
                                                           })
                                                       }
                                                       } placeholder="Select Product"
                                                       defaultValue={this.productName(this.state.productCode)}>
                                                {productOptions}
                                                </select>
                                            }
                                            
                                        </div>

                                        <div className="mt-4 width-half">
                                            <span className="mt-4">When a user is removed from the AD Group:</span><br/>
                                            <div className="ml-4 mt-1">
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="remove"
                                                        checked={this.state.deleteAction === 'delete'}
                                                        onChange={() => {
                                                            this.setState({deleteAction: 'delete',
                                                                choiceModalVisible: false,
                                                                confirmModalVisible: false,
                                                                alertModalVisible: false});
                                                        }}
                                                    />
                                                    <span className="ml-1">Remove Dubber User and Delete Recordings</span>
                                                </label><br/>
                                                <label>
                                                    <input
                                                        type="radio"
                                                        value="remove"
                                                        checked={this.state.deleteAction === 'suspend'}
                                                        onChange={() => {
                                                            this.setState({
                                                                deleteAction: 'suspend',
                                                                choiceModalVisible: false,
                                                                confirmModalVisible: false,
                                                                alertModalVisible: false});
                                                        }}
                                                    />
                                                    <span className="ml-1">Suspend Dub Point (Recommended)</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="mt-4 width-half">
                                            <button onClick={this.onSaveChanges} disabled={this.state.isSaveChangesDisabled} className={this.state.isSaveChangesDisabled ? "muted-link" : "next-link"}>Save Changes</button>
                                            <button onClick={this.syncNow} disabled={this.state.isRunNowDisabled || this.state.isSaveChangesDisabled} className={this.state.isRunNowDisabled || this.state.isSaveChangesDisabled ? "muted-link" : "next-link"}>Run Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ChoiceModal
                    Visible={this.state.choiceModalVisible}
                    Title="Select Active Directory Group"
                    BodyText="Select a security group to sync with Dubber"
                    ChoiceText="Group to sync:"
                    Filter="MS Teams Dubber Recording.*"
                    Options={choiceOptions}
                    OnOk={(groupId) => {
                        this.createSyncPlan(groupId);
                        this.setState({choiceModalVisible: false}, this.forceUpdate)
                    }}
                    OnCancel={() => {
                        this.setState({choiceModalVisible: false}, this.forceUpdate)
                    }}
                    OnNoItems={this.OnNoItems}
                    />
                
                <ConfirmModal
                    Visible={this.state.confirmModalVisible}
                    Title="Are you sure?"
                    BodyText="Are you sure you want to delete this synchronization plan?"
                    OnYes={this.deleteSyncPlan}
                    />
                
                <AlertModal
                    Title={this.state.alertModalTitle}
                    BodyText={this.state.alertModalText}
                    OnOk={() => {this.setState({alertModalVisible: false}, this.forceUpdate)}}
                    Visible={this.state.alertModalVisible}
                />
            </div>
        )
    }
}

export { AdSyncSettings }
